<template>
    <div>
      <div v-if="!editing" @click="editing = true">
        <span class="editable-border-bottom" :class="{'grey--text text--lighten-1': !value }">{{ !!value ? value : $t('default_content.no_label') }}
          <v-icon :class="{'hidden-table-action': $vuetify.breakpoint.mdAndUp }"  class="body-2 ml-2">create</v-icon>
        </span>
      </div>
      <v-text-field
        v-else
        class="pa-0 my-2"
        hide-details
        :value="value"
        v-model="newValue"
        autofocus
        @blur="!loading ? editing = false : ''"
        single-line
        :loading="loading"
        :readonly="loading"
        :append-icon="loading ? 'access_time' : 'check'"
        @click:append="!loading ? edited() : ''"
        v-on:keyup.enter.prevent="edited()"
      ></v-text-field>
    </div>
</template>
<script>
export default {
  props: {
    value: [String, Number],
    resetLoading: Date
  },
  data () {
    return {
      editing: false,
      newValue: null,
      loading: false
    }
  },
  methods: {
    edited () {
      this.loading = true
      this.$emit('edited', this.newValue)
    }
  },
  created () {
    this.newValue = this.value
  },
  watch: {
    value (value) {
      this.newValue = value
    },
    editing (value) {
      if (!this.editing) {
        this.newValue = this.value
      }
    },
    resetLoading () {
      this.loading = false
      this.editing = false
    }
  }
}
</script>
